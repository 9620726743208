<template>
  <div class="m-3 p-3 bg-white rounded-xl text-black h-full">
    <div v-if="!mounting && hasBadt">
      <div class="flex justify-end">
        <div class>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="close"
            class="cursor-pointer"
          >
            <path
              d="M6 18.4998L17.9998 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.9998 18.4998L6 6.5"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="h-full">
        <div v-if="patients" class="row">
          <div class="col-md-3">
            <div class="fs-20 fw-500">{{patients ? patients.name : ''}}</div>
            <div class="flex justify-between items-center">
              <div>{{`${getGender()}${getYear()} ${patients.age ? `(${patients.age} tuổi)` : ''} `}}</div>
              <div></div>
            </div>
          </div>
          <div class="col-md-6 flex justify-around items-end">
            <div>
              <span class="font-bold">CCCD:</span>
              {{patients ? patients.national_id : ''}}
            </div>
            <div>
              <span class="font-bold">BHYT:</span>
              {{patients ? patients.insurance_number : ''}}
            </div>
            <div>
              <span class="font-bold">PID:</span>
              {{patients ? patients.patient_code : ''}}
            </div>
          </div>
        </div>
        <div v-if="!showDetail" class="row mb-3 h-full mt-10">
          <div v-if="MRByPatientId && MRByPatientId.count">
            <div class="flex items-center">
              <div class="flex cursor-pointer select-none" @click="toggleMR">
                <div>
                  <svg
                    :class="!showMR ? 'fliped -mt-1' : '-mt-0.5'"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.09973 9.5L12.0997 14.5L17.0997 9.5H7.09973Z" fill="#1D2939" />
                  </svg>
                </div>
                <div class="fs-14 fw-500">Bệnh án ({{MRByPatientId.count}})</div>
              </div>
            </div>
            <div class="row mt-3 mx-0.5" v-show="showMR">
              <div
                class="col-md-2 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-3 cursor-pointer"
                @click="onShowModalAdd(true)"
              >
                <div class="flex flex-col justify-center items-center">
                  <div>
                    <svg
                      class="w-14 h-14"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.1"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                        fill="#476DD6"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                        fill="#476DD6"
                      />
                    </svg>
                  </div>
                  <div class="fs-16 fw-600 mt-2.5">Thêm bệnh án</div>
                </div>
              </div>
              <div
                class="relative text-base col-md-2 border bg-gray-100 hover:bg-gray-200 border-gray-300 cursor-pointer rounded-2xl p-3 mr-3 fs-12 fw-500 text-gray-700"
                v-for="medical_record in MRByPatientId.data"
                :key="medical_record.id"
                @click="goToDetail(medical_record)"
              >
                <div data-label="Hồ sơ" style="text-transform: capitalize;">
                  <p
                    class="mb-0 fs-14 fw-600"
                  >{{medical_record ? medical_record.medical_record_name : ''}}</p>
                </div>
                <div
                  class="fs-11"
                >Cập nhật: {{medical_record && medical_record.updated_at ? formatHMDMY(medical_record.updated_at) : ''}}</div>
                <div v-if="medical_record.main_doctor">BS chính: {{medical_record.main_doctor.name}}</div>
                <div
                  v-if="medical_record.treat_doctor"
                >BS Điều trị: {{medical_record.treat_doctor.name}}</div>
                <div v-if="medical_record.state">
                  {{medical_record.state.checklist && medical_record.state.checklist.name}}:
                  <span
                    class="fw-700"
                  >{{medical_record.state.name}}</span>
                </div>
                <div class="mb-3.5">
                  <div v-if="$globalClinic" class="mt-1">{{$globalClinic.name}}</div>
                  <div v-if="$globalClinic" class="font-bold">{{$globalClinic.org_name}}</div>
                </div>
                <div class="absolute bottom-3 right-3">
                  <svg
                    width="15"
                    height="21"
                    viewBox="0 0 15 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1533 0.220703H1.15332C0.888104 0.220703 0.63375 0.32606 0.446214 0.513596C0.258677 0.701133 0.15332 0.955487 0.15332 1.2207V19.2207C0.153308 19.4119 0.208089 19.599 0.311174 19.76C0.41426 19.921 0.561329 20.0491 0.734961 20.1291C0.908592 20.209 1.10151 20.2376 1.29086 20.2113C1.4802 20.185 1.65805 20.105 1.80332 19.9807L7.15332 15.4907L12.4433 19.9307C12.5368 20.0234 12.6476 20.0967 12.7694 20.1465C12.8913 20.1962 13.0217 20.2215 13.1533 20.2207C13.2845 20.2241 13.4146 20.1967 13.5333 20.1407C13.7159 20.0657 13.8723 19.9383 13.9826 19.7746C14.0929 19.6108 14.1523 19.4181 14.1533 19.2207V1.2207C14.1533 0.955487 14.048 0.701133 13.8604 0.513596C13.6729 0.32606 13.4185 0.220703 13.1533 0.220703ZM12.1533 17.0807L7.79332 13.4207C7.61363 13.271 7.38717 13.1891 7.15332 13.1891C6.91947 13.1891 6.69301 13.271 6.51332 13.4207L2.15332 17.0807V2.2207H12.1533V17.0807Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!MRByPatientId || MRByPatientId.total === 0"
            class="flex justify-center items-center h-5/6"
          >
            <div class>
              <img src="../../assets/images/BADT.svg" alt />
              <div class="flex justify-center items-center mt-3">
                <ButtonHoDo
                  @click="onShowModalAdd(true)"
                  title="Tạo mới bệnh án"
                  class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
                />
              </div>
            </div>
          </div>
          <ModalAddBenhAn @refresh="onRefresh" :patientParent="patients" :noModalAddPatient="true" />
        </div>

        <div v-else>
          <div @click="closeDetail" class="cursor-pointer btn">Hủy</div>
          <div class="h-full mt-3">
            <div class="row mb-3 h-full mt-10 mx-auto px-3">
              <div>
                <div class="row mt-3">
                  <div
                    @click="goToPcs('master')"
                    class="col-md-2 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-between">
                      <svg
                        class="ml-2"
                        width="15"
                        height="21"
                        viewBox="0 0 15 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1533 0.220703H1.15332C0.888104 0.220703 0.63375 0.32606 0.446214 0.513596C0.258677 0.701133 0.15332 0.955487 0.15332 1.2207V19.2207C0.153308 19.4119 0.208089 19.599 0.311174 19.76C0.41426 19.921 0.561329 20.0491 0.734961 20.1291C0.908592 20.209 1.10151 20.2376 1.29086 20.2113C1.4802 20.185 1.65805 20.105 1.80332 19.9807L7.15332 15.4907L12.4433 19.9307C12.5368 20.0234 12.6476 20.0967 12.7694 20.1465C12.8913 20.1962 13.0217 20.2215 13.1533 20.2207C13.2845 20.2241 13.4146 20.1967 13.5333 20.1407C13.7159 20.0657 13.8723 19.9383 13.9826 19.7746C14.0929 19.6108 14.1523 19.4181 14.1533 19.2207V1.2207C14.1533 0.955487 14.048 0.701133 13.8604 0.513596C13.6729 0.32606 13.4185 0.220703 13.1533 0.220703ZM12.1533 17.0807L7.79332 13.4207C7.61363 13.271 7.38717 13.1891 7.15332 13.1891C6.91947 13.1891 6.69301 13.271 6.51332 13.4207L2.15332 17.0807V2.2207H12.1533V17.0807Z"
                          fill="#20419B"
                        />
                      </svg>
                      <!-- <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" rx="12" fill="#F04438"/>
                          <path d="M13.3146 7.27273V16H11.9936V8.59375H11.9425L9.8544 9.95739V8.69602L12.032 7.27273H13.3146Z" fill="white"/>
                      </svg>-->
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Tờ Bệnh Án</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-between">
                      <svg
                        class="ml-2"
                        width="15"
                        height="21"
                        viewBox="0 0 15 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1533 0.220703H1.15332C0.888104 0.220703 0.63375 0.32606 0.446214 0.513596C0.258677 0.701133 0.15332 0.955487 0.15332 1.2207V19.2207C0.153308 19.4119 0.208089 19.599 0.311174 19.76C0.41426 19.921 0.561329 20.0491 0.734961 20.1291C0.908592 20.209 1.10151 20.2376 1.29086 20.2113C1.4802 20.185 1.65805 20.105 1.80332 19.9807L7.15332 15.4907L12.4433 19.9307C12.5368 20.0234 12.6476 20.0967 12.7694 20.1465C12.8913 20.1962 13.0217 20.2215 13.1533 20.2207C13.2845 20.2241 13.4146 20.1967 13.5333 20.1407C13.7159 20.0657 13.8723 19.9383 13.9826 19.7746C14.0929 19.6108 14.1523 19.4181 14.1533 19.2207V1.2207C14.1533 0.955487 14.048 0.701133 13.8604 0.513596C13.6729 0.32606 13.4185 0.220703 13.1533 0.220703ZM12.1533 17.0807L7.79332 13.4207C7.61363 13.271 7.38717 13.1891 7.15332 13.1891C6.91947 13.1891 6.69301 13.271 6.51332 13.4207L2.15332 17.0807V2.2207H12.1533V17.0807Z"
                          fill="#20419B"
                        />
                      </svg>
                      <svg
                        class="mr-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#F04438" />
                        <path
                          d="M13.3146 7.27273V16H11.9936V8.59375H11.9425L9.8544 9.95739V8.69602L12.032 7.27273H13.3146Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Phiếu điều trị</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-between">
                      <svg
                        class="ml-2"
                        width="15"
                        height="21"
                        viewBox="0 0 15 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1533 0.220703H1.15332C0.888104 0.220703 0.63375 0.32606 0.446214 0.513596C0.258677 0.701133 0.15332 0.955487 0.15332 1.2207V19.2207C0.153308 19.4119 0.208089 19.599 0.311174 19.76C0.41426 19.921 0.561329 20.0491 0.734961 20.1291C0.908592 20.209 1.10151 20.2376 1.29086 20.2113C1.4802 20.185 1.65805 20.105 1.80332 19.9807L7.15332 15.4907L12.4433 19.9307C12.5368 20.0234 12.6476 20.0967 12.7694 20.1465C12.8913 20.1962 13.0217 20.2215 13.1533 20.2207C13.2845 20.2241 13.4146 20.1967 13.5333 20.1407C13.7159 20.0657 13.8723 19.9383 13.9826 19.7746C14.0929 19.6108 14.1523 19.4181 14.1533 19.2207V1.2207C14.1533 0.955487 14.048 0.701133 13.8604 0.513596C13.6729 0.32606 13.4185 0.220703 13.1533 0.220703ZM12.1533 17.0807L7.79332 13.4207C7.61363 13.271 7.38717 13.1891 7.15332 13.1891C6.91947 13.1891 6.69301 13.271 6.51332 13.4207L2.15332 17.0807V2.2207H12.1533V17.0807Z"
                          fill="#20419B"
                        />
                      </svg>
                      <svg
                        class="mr-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#F04438" />
                        <path
                          d="M13.3146 7.27273V16H11.9936V8.59375H11.9425L9.8544 9.95739V8.69602L12.032 7.27273H13.3146Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Xét Nghiệm/Cận lâm sàng</div>
                    </div>
                  </div>

                  <div
                    @click="goToPcs('pcs')"
                    class="col-md-2 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-end">
                      <!-- <svg class="ml-2"
                            width="15"
                            height="21"
                            viewBox="0 0 15 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.1533 0.220703H1.15332C0.888104 0.220703 0.63375 0.32606 0.446214 0.513596C0.258677 0.701133 0.15332 0.955487 0.15332 1.2207V19.2207C0.153308 19.4119 0.208089 19.599 0.311174 19.76C0.41426 19.921 0.561329 20.0491 0.734961 20.1291C0.908592 20.209 1.10151 20.2376 1.29086 20.2113C1.4802 20.185 1.65805 20.105 1.80332 19.9807L7.15332 15.4907L12.4433 19.9307C12.5368 20.0234 12.6476 20.0967 12.7694 20.1465C12.8913 20.1962 13.0217 20.2215 13.1533 20.2207C13.2845 20.2241 13.4146 20.1967 13.5333 20.1407C13.7159 20.0657 13.8723 19.9383 13.9826 19.7746C14.0929 19.6108 14.1523 19.4181 14.1533 19.2207V1.2207C14.1533 0.955487 14.048 0.701133 13.8604 0.513596C13.6729 0.32606 13.4185 0.220703 13.1533 0.220703ZM12.1533 17.0807L7.79332 13.4207C7.61363 13.271 7.38717 13.1891 7.15332 13.1891C6.91947 13.1891 6.69301 13.271 6.51332 13.4207L2.15332 17.0807V2.2207H12.1533V17.0807Z"
                              fill="#20419B"
                            />
                      </svg>-->
                      <svg
                        class="mr-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#F04438" />
                        <path
                          d="M13.3146 7.27273V16H11.9936V8.59375H11.9425L9.8544 9.95739V8.69602L12.032 7.27273H13.3146Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Phiếu chăm sóc</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Biên bản Hội chẩn</div>
                    </div>
                  </div>

                  <div
                    class="h-36 col-md-2 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-end">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="0.311768" width="24" height="24" rx="12" fill="#98A2B3" />
                        <path
                          d="M9.47157 16V15.0455L12.4247 11.9858C12.74 11.6534 13 11.3622 13.2045 11.1122C13.4119 10.8594 13.5667 10.6193 13.669 10.392C13.7713 10.1648 13.8224 9.9233 13.8224 9.66761C13.8224 9.37784 13.7542 9.12784 13.6179 8.91761C13.4815 8.70455 13.2954 8.54119 13.0596 8.42756C12.8238 8.31108 12.5582 8.25284 12.2628 8.25284C11.9503 8.25284 11.6775 8.31676 11.4446 8.4446C11.2116 8.57244 11.0326 8.75284 10.9076 8.9858C10.7826 9.21875 10.7201 9.49148 10.7201 9.80398H9.46305C9.46305 9.27273 9.58521 8.80824 9.82952 8.41051C10.0738 8.01278 10.4091 7.70455 10.8352 7.4858C11.2613 7.2642 11.7457 7.15341 12.2883 7.15341C12.8366 7.15341 13.3196 7.26278 13.7372 7.48153C14.1576 7.69744 14.4858 7.9929 14.7216 8.3679C14.9574 8.74006 15.0753 9.16051 15.0753 9.62926C15.0753 9.95312 15.0142 10.2699 14.892 10.5795C14.7727 10.8892 14.5639 11.2344 14.2656 11.6151C13.9673 11.9929 13.5525 12.4517 13.0213 12.9915L11.2869 14.8068V14.8707H15.2159V16H9.47157Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Phiếu sơ kết Điều trị</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 mt-4 h-36 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Tóm tắt Thông qua mổ</div>
                    </div>
                  </div>

                  <div
                    class="h-36 col-md-2 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-end">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="0.311768" width="24" height="24" rx="12" fill="#98A2B3" />
                        <path
                          d="M9.47157 16V15.0455L12.4247 11.9858C12.74 11.6534 13 11.3622 13.2045 11.1122C13.4119 10.8594 13.5667 10.6193 13.669 10.392C13.7713 10.1648 13.8224 9.9233 13.8224 9.66761C13.8224 9.37784 13.7542 9.12784 13.6179 8.91761C13.4815 8.70455 13.2954 8.54119 13.0596 8.42756C12.8238 8.31108 12.5582 8.25284 12.2628 8.25284C11.9503 8.25284 11.6775 8.31676 11.4446 8.4446C11.2116 8.57244 11.0326 8.75284 10.9076 8.9858C10.7826 9.21875 10.7201 9.49148 10.7201 9.80398H9.46305C9.46305 9.27273 9.58521 8.80824 9.82952 8.41051C10.0738 8.01278 10.4091 7.70455 10.8352 7.4858C11.2613 7.2642 11.7457 7.15341 12.2883 7.15341C12.8366 7.15341 13.3196 7.26278 13.7372 7.48153C14.1576 7.69744 14.4858 7.9929 14.7216 8.3679C14.9574 8.74006 15.0753 9.16051 15.0753 9.62926C15.0753 9.95312 15.0142 10.2699 14.892 10.5795C14.7727 10.8892 14.5639 11.2344 14.2656 11.6151C13.9673 11.9929 13.5525 12.4517 13.0213 12.9915L11.2869 14.8068V14.8707H15.2159V16H9.47157Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Phiếu thủ thuật, phẫu thuật</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Phiếu khám gây mê trước mổ</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Phiếu gây mê hồi sức</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border mt-4 bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Chẩn đoán hình ảnh</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5 text-center">Phiếu theo dõi chức năng sống</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border mt-4 bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div class="fs-16 fw-600 mt-2.5">Phiếu đánh giá dinh dưỡng</div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 h-36 border mt-4 bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex flex-col justify-center items-center">
                      <div>
                        <svg
                          class="w-14 h-14"
                          width="80"
                          height="80"
                          viewBox="0 0 80 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.1"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                            fill="#476DD6"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M41.6001 38.3996H52.8001C53.6838 38.3996 54.4001 39.116 54.4001 39.9996C54.4001 40.8833 53.6838 41.5996 52.8001 41.5996H41.6001V52.7996C41.6001 53.6833 40.8838 54.3996 40.0001 54.3996C39.1164 54.3996 38.4001 53.6833 38.4001 52.7996V41.5996H27.2001C26.3164 41.5996 25.6001 40.8833 25.6001 39.9996C25.6001 39.116 26.3164 38.3996 27.2001 38.3996H38.4001V27.1996C38.4001 26.316 39.1164 25.5996 40.0001 25.5996C40.8838 25.5996 41.6001 26.316 41.6001 27.1996V38.3996Z"
                            fill="#476DD6"
                          />
                        </svg>
                      </div>
                      <div
                        class="fs-16 fw-600 mt-2.5 text-center"
                      >Phiếu công khai thuốc và dịch vụ Y tế</div>
                    </div>
                  </div>

                  <div
                    class="h-36 col-md-2 mt-4 border bg-gray-100 hover:bg-gray-200 border-gray-300 rounded-2xl py-6 mr-4 cursor-pointer"
                  >
                    <div class="flex justify-end">
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="0.311768" width="24" height="24" rx="12" fill="#98A2B3" />
                        <path
                          d="M9.47157 16V15.0455L12.4247 11.9858C12.74 11.6534 13 11.3622 13.2045 11.1122C13.4119 10.8594 13.5667 10.6193 13.669 10.392C13.7713 10.1648 13.8224 9.9233 13.8224 9.66761C13.8224 9.37784 13.7542 9.12784 13.6179 8.91761C13.4815 8.70455 13.2954 8.54119 13.0596 8.42756C12.8238 8.31108 12.5582 8.25284 12.2628 8.25284C11.9503 8.25284 11.6775 8.31676 11.4446 8.4446C11.2116 8.57244 11.0326 8.75284 10.9076 8.9858C10.7826 9.21875 10.7201 9.49148 10.7201 9.80398H9.46305C9.46305 9.27273 9.58521 8.80824 9.82952 8.41051C10.0738 8.01278 10.4091 7.70455 10.8352 7.4858C11.2613 7.2642 11.7457 7.15341 12.2883 7.15341C12.8366 7.15341 13.3196 7.26278 13.7372 7.48153C14.1576 7.69744 14.4858 7.9929 14.7216 8.3679C14.9574 8.74006 15.0753 9.16051 15.0753 9.62926C15.0753 9.95312 15.0142 10.2699 14.892 10.5795C14.7727 10.8892 14.5639 11.2344 14.2656 11.6151C13.9673 11.9929 13.5525 12.4517 13.0213 12.9915L11.2869 14.8068V14.8707H15.2159V16H9.47157Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center items-center h-full">
                      <div class="fs-16 fw-600 mt-2.5">Biểu mẫu hành chính</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!mounting && !hasBadt" class="d-flex align-items-center justify-content-center py-5">
      <p class="fs-16 fw-500 text-center">Chức này không khả dụng với Workspace hiện tại!</p>
    </div>
    <div v-if="mounting" class="d-flex align-items-center justify-content-center py-5">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { ButtonHoDo } from '@/components/Button'
import PersonForm from '../../components/PersonForm'
import ModalAddBenhAn from '../BenhAn/ModalAddBenhAn.vue'
import _ from 'lodash'
import { APP_MODE } from '../../utils/constants'
export default {
  name: 'PatientDetail',
  components: { PersonForm, ModalAddBenhAn, ButtonHoDo },
  data () {
    return {
      MRByPatientId: null,
      patients: null,
      showMR: true,
      mounting: false,
      showDetail: false,
      MRDetailID: null
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  computed: {
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    }
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    }
  },
  mounted () {
    this.getPatient()
    this.getWorkspaceMRByPatientId()
  },
  methods: {
    onRefresh () {
      this.getPatient()
      this.getWorkspaceMRByPatientId()
    },
    close () {
      this.$router.go(-1)
    },
    formatHMDMY (date_time) {
      return window.moment(date_time).format('HH:mm, DD/MM/YY')
    },
    async getWorkspaceMRByPatientId () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getWorkspaceMRByPatientId(
          this.$globalClinic.id,
          this.$route?.params?.id
        )
      this.MRByPatientId = res.data
    },
    async getPatient () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getPatientWithID(this.$globalClinic.id, this.$route?.params?.id)
      this.patients = res.data
    },
    onShowModalAdd (show) {
      window.$('#modal__add_benhan').modal(show ? 'show' : 'hide')
    },
    getGender () {
      return appUtils.getGender(this.patients.gender)
    },
    getYear () {
      if (!this.patients.birthday) return ' '
      return ', ' + window.moment(this.patients.birthday).format('YYYY')
    },
    toggleMR () {
      this.showMR = !this.showMR
    },
    goToDetail (item) {
      if (!item.id) {
        alert('BADT không tồn tại!')
        return
      }
      // this.$router.push({ name: 'ElectronicMedicalRecords', params: { id: item.id } }).catch((e) => { })
      this.showDetail = true
      this.MRDetailID = item.id
    },
    closeDetail () {
      this.showDetail = false
    },
    goToPcs (tab) {
      this.$router
        .push({
          name: 'ElectronicMedicalRecords',
          params: { id: this.MRDetailID },
          query: { tab }
        })
        .catch(e => {})
    }
  }
}
</script>

<style scoped>
.row-cases {
  background-color: #f2f5fa !important;
  cursor: pointer;
  border-radius: 10px;
}

.cases-next {
  color: #b6c2cf;
}

.row-cases:hover {
  background-color: #ebf1ff !important;
  cursor: pointer;
}

.row-cases:hover .cases-next {
  color: black;
}

.border-top-left {
  border-left: 1px solid #b6c2cf;
  border-top: 1px solid #b6c2cf;
}

.split-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ml-10px {
  margin-left: 10px;
}

.p-10px {
  padding: 10px;
}

.icon-walking {
  background-image: url("../../../public/assets/images/icon/white-walking.svg");
  background-repeat: no-repeat;
  background-size: 20px 26px;
  background-color: #0e7c71;
  height: 36px;
  width: 36px;
}

.icon-video {
  background-image: url("../../../public/assets/images/icon/white_video.svg");
  background-repeat: no-repeat;
  background-size: 20px 15px;
  background-color: #20419b;
  background-origin: center;
  background-position: center;
  height: 36px;
  width: 36px;
}

.icon-detail {
  background-image: url("../../../public/assets/images/icon/next.svg");
  background-repeat: no-repeat;
  background-size: 14px 7px;
  background-color: #20419b;
  background-origin: center;
  background-position: center;
  height: 7px;
  width: 14px;
}

.bg-bold-item {
  background-color: #e7eaf5 !important;
}
.fliped {
  transform: rotate(270deg);
}
</style>
